<!--
 * @Description: 新装明细统计报表
 * @Author: LiangYiNing
 * @Date: 2022-04-11 16:52:00
 * @LastEditTime: 2022-07-20 18:19:59
 * @LastEditors: ChenXueLin
-->

<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
        <!--    v-has="EXPORT_BUTTON_ID" -->
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="corpName">
              <el-input
                v-model="searchForm.corpName"
                placeholder="公司"
                title="公司"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineerName">
              <el-input
                v-model="searchForm.engineerName"
                placeholder="工程师"
                title="工程师"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="dataEntryPerson">
              <el-input
                v-model="searchForm.dataEntryPerson"
                placeholder="数据录入人"
                title="数据录入人"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="secondClassCode">
              <e6-vr-select
                v-model="searchForm.secondClassCode"
                :data="taskSecondClassEnum"
                placeholder="调度类型"
                title="调度类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="workTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="作业时间"
                ref="workTime"
                type="datetime"
                v-model="searchForm.workTime"
                title="作业时间"
                :picker-options="pickerOptions('searchForm.workTime')"
                start-placeholder="作业时间(始)"
                end-placeholder="作业时间(止)"
                :default-time="['00:00:00', '23:59:59']"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="finishTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="完成时间"
                ref="finishTime"
                type="datetime"
                v-model="searchForm.finishTime"
                title="完成时间"
                :picker-options="pickerOptions('searchForm.finishTime')"
                start-placeholder="完成时间(始)"
                end-placeholder="完成时间(止)"
                :default-time="['00:00:00', '23:59:59']"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="tableList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  newInstallDetailsReportList,
  newInstallDetailsReportExport,
  findDownList
} from "@/api";
import { printError, getMothRangeTimestamp } from "@/utils/util";
import { exportData } from "@/utils/download";

// 按钮ID常量
import setting from "@/setting.js";
let { EXPORT_BUTTON_ID } = setting;

export default {
  name: "NewInstallDetailedStatisticsReport",
  data() {
    return {
      TrensferFunsApplyVisible: false,
      total: 0,
      timePickerResetList: ["workTime", "finishTime"], // base mixin的配置项 需要重置的timePicker refname
      taskSecondClassEnum: [], // 调度类型（任务单类型）
      EXPORT_BUTTON_ID,
      queryListAPI: newInstallDetailsReportList,
      searchForm: {
        undefinedinput: "",
        undefinedSelect: "",
        corpName: "", // 公司名
        engineerName: "", // 工程师
        dataEntryPerson: "", // 数据录入人
        secondClassCode: "", // 调度类型
        workTime: [], //作业时间
        workTimeStartDate: "", //作业时间开始时间
        workTimeEndDate: "", //作业时间结束时间
        finishTime: getMothRangeTimestamp(), // 完成时间
        finishStartDate: "", // 完成开始时间
        finishEndDate: "", // 完成结束时间
        pageIndex: 1,
        pageSize: 20,
        sortIndex: "", // 默认创建时间排序
        sortDir: "" // 默认降序
      },
      columnData: [
        {
          fieldName: "corpName",
          fieldLabel: "公司",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sourceOrganizationName",
          fieldLabel: "上线平台",
          width: 100,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "companyFullName",
          fieldLabel: "所属主体",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "billingCorpName",
          fieldLabel: "开票公司名称",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "nowOperationName",
          fieldLabel: "现运营",
          width: 130,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "nowOperationOrgName",
          fieldLabel: "现运营组织",
          width: 130,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "nowSaleName",
          fieldLabel: "现销售",
          width: 130,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "nowSaleOrgName",
          fieldLabel: "现销售组织",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "signCustomerId",
          fieldLabel: "签约客户ID",
          width: 130,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "companyNoName",
          fieldLabel: "签单组织",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "officeName",
          fieldLabel: "所属办事处",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "salesman",
          fieldLabel: "业务员",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneName",
          fieldLabel: "车牌",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "itemStatus",
          fieldLabel: "当前状态",
          width: 100,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "carModel",
          fieldLabel: "厢车柜车",
          width: 100,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "insbodyName",
          fieldLabel: "设备安装位置",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          fieldLabel: "调度类型",
          width: 100,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerName",
          fieldLabel: "工程师",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "dataEntryPerson",
          fieldLabel: "数据录入",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipType",
          fieldLabel: "商品类型",
          width: 100,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installStatus",
          fieldLabel: "安装状态",
          width: 100,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClass",
          fieldLabel: "设备一级分类",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClass",
          fieldLabel: "设备二级分类",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClass",
          fieldLabel: "设备三级分类",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipCode",
          fieldLabel: "设备编号",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "cardOpName",
          fieldLabel: "卡操作",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "simNo",
          fieldLabel: "SIM卡号",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "simBindingTime",
          fieldLabel: "SIM卡绑定时间",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "simBindingDay",
          fieldLabel: "原SIM卡绑定时长",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isChangeSimName",
          fieldLabel: "是否更换SIM卡",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workTimeStr",
          fieldLabel: "作业时间",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "finishDateStr",
          fieldLabel: "完成时间",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactName",
          fieldLabel: "第二联系人",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactPhone",
          fieldLabel: "第二联系方式",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workAddress",
          fieldLabel: "作业地点",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isSign",
          fieldLabel: "是否上门",
          width: 100,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: []
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.workTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.workTime = this.searchForm.workTime.reverse();
        }
        this.searchForm.workTimeStartDate = val[0] || "";
        this.searchForm.workTimeEndDate = val[1] || "";
      }
    },
    // 完成时间
    "searchForm.finishTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.finishTime = this.searchForm.finishTime.reverse();
        }
        this.searchForm.finishStartDate = val[0] || "";
        this.searchForm.finishEndDate = val[1] || "";
      }
    }
  },
  created() {
    this.queryList();
    this.initData();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 获取枚举信息
    async initData() {
      try {
        let enumKey = ["taskSecondClass"];
        //获取枚举数据
        let promiseList = [findDownList(enumKey)];
        let [findDownListRes] = await Promise.all(promiseList);
        enumKey.map(item => {
          this[`${item}Enum`] = this.getFreezeResponse(findDownListRes, {
            path: `data.${item}`
          });
        });
      } catch (error) {
        printError(error);
      }
    },
    // 表格勾选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.simPropId;
      });
    },
    // 新增
    addTrensferFunsApply() {
      this.TrensferFunsApplyVisible = true;
      this.$nextTick(() => {
        this.$refs.TrensferFunsApply.init();
      });
    },
    // 导出
    handleExport() {
      exportData(this, newInstallDetailsReportExport);
    }
  }
};
</script>
<style lang="scss" scoped></style>
